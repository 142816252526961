<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].insurance_policy_id.label"
                              :label="beForm[rep].insurance_policy_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('insurance_policy_id')"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchPolicy"
                              :disabled="true"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="insurance_policy_id col-md-4">
                      <span
                        v-if="insurance_policy_data"
                        class="info"
                        v-html="
                          toInfoData(
                            insurance_policy_data,
                            'insurance_policy_ext1',
                            0
                          )
                        "
                      >
                      </span>
                    </div>
                    <div class="insurance_policy_id col-md-4">
                      <span
                        v-if="insurance_policy_data"
                        class="info"
                        v-html="
                          toInfoData(
                            insurance_policy_data,
                            'insurance_policy_ext2',
                            0
                          )
                        "
                      >
                      </span>
                    </div>
                    <quick-search-policy-modal
                      ref="quickSearchPolicy"
                      @input="handlePolicyModalInput"
                    ></quick-search-policy-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].number.label"
                        vid="number"
                        :label="beForm[rep].number.label"
                        v-model="form[rep].number"
                        placeholder="Inserisci un numero sinistro"
                        :rules="getRules('number')"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].claim_type.label"
                        vid="claim_type"
                        :label="beForm[rep].claim_type.label"
                        v-model="form[rep].claim_type"
                        :options="beForm[rep].claim_type.options"
                        :rules="getRules('claim_type')"
                        :disabled="isImported()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].status_claim.label"
                        vid="status_claim"
                        :label="beForm[rep].status_claim.label"
                        v-model="form[rep].status_claim"
                        :options="beForm[rep].status_claim.options"
                        :rules="getRules('status_claim')"
                        :disabled="isImported()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].insurer_risk_branch.label"
                        vid="insurer_risk_branch"
                        :label="beForm[rep].insurer_risk_branch.label"
                        v-model="form[rep].insurer_risk_branch"
                        placeholder="Inserisci un ramo sinistro"
                        :rules="getRules('insurer_risk_branch')"
                        :disabled="isImported()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="referring_at"
                        :name="beForm[rep].referring_at.label"
                        :label="beForm[rep].referring_at.label"
                        v-model="form[rep].referring_at"
                        type="number"
                        :readonly="true"
                        :rules="getRules('referring_at')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <correspondence-form
                v-model="correspondence_form"
                :beECForm="beForm[cor]"
                :repository="cor"
                :disabled="isImported()"
              >
              </correspondence-form>
              <b-card
                header="Altre Informazioni"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].dcpd_module_type.label"
                        vid="dcpd_module_type"
                        :label="beForm[rep].dcpd_module_type.label"
                        v-model="form[rep].dcpd_module_type"
                        :options="beForm[rep].dcpd_module_type.options"
                        :rules="getRules('dcpd_module_type')"
                        :disabled="isImported()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-radio
                        :name="beForm[rep].dcpd_module_filled.label"
                        vid="dcpd_module_filled"
                        :label="beForm[rep].dcpd_module_filled.label"
                        v-model="form[rep].dcpd_module_filled"
                        :options="beForm[rep].dcpd_module_filled.options"
                        :rules="getRules('dcpd_module_filled')"
                        stacked
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].is_covered.label"
                        vid="is_covered"
                        :label="beForm[rep].is_covered.label"
                        v-model="form[rep].is_covered"
                        :options="beForm[rep].is_covered.options"
                        :rules="getRules('is_covered')"
                        :disabled="isImported()"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].deductible.label"
                        vid="deductible"
                        :label="beForm[rep].deductible.label"
                        v-model="form[rep].deductible"
                        :rules="getRules('deductible')"
                        placeholder="Inserisci franchigia"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].amount_in_reserve.label"
                        vid="amount_in_reserve"
                        :label="beForm[rep].amount_in_reserve.label"
                        v-model="form[rep].amount_in_reserve"
                        :rules="getRules('amount_in_reserve')"
                        placeholder="Inserisci ammontare in riserva"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].settlement_expense.label"
                        vid="settlement_expense"
                        :label="beForm[rep].settlement_expense.label"
                        v-model="form[rep].settlement_expense"
                        :rules="getRules('settlement_expense')"
                        placeholder="Inserisci spese di liquidazione"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :disabled="isImported()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].settled_total.label"
                        vid="settled_total"
                        :label="beForm[rep].settled_total.label"
                        v-model="form[rep].settled_total"
                        placeholder="Inserisci totale liquidato"
                        :rules="getRules('settled_total')"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :disabled="isImported()"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="title"
                        :name="beForm[rep].title.label"
                        label="Descrizione Accadimento"
                        v-model="form[rep].title"
                        :rules="getRules('title')"
                        :disabled="isImported()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="occurred_at"
                        :name="beForm[rep].occurred_at.label"
                        :label="beForm[rep].occurred_at.label"
                        v-model="form[rep].occurred_at"
                        :readonly="isImported()"
                        :disabled="isImported()"
                        :rules="getRules('occurred_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="partially_settled_at"
                        :name="beForm[rep].partially_settled_at.label"
                        :label="beForm[rep].partially_settled_at.label"
                        v-model="form[rep].partially_settled_at"
                        :rules="getRules('partially_settled_at')"
                        :readonly="isImported()"
                        :disabled="isImported()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].place.label"
                        vid="place"
                        :label="beForm[rep].place.label"
                        v-model="form[rep].place"
                        placeholder="Inserisci un luogo"
                        :rules="getRules('place')"
                        :disabled="isImported()"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <custom-inputs
                        v-for="(element, index) in customInputs[rep]"
                        :key="index"
                        :input="element"
                        v-model="form[rep]"
                        :beForm="beForm[rep]"
                        :beRules="beRules[rep]"
                      />
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import { mapGetters } from "vuex";
import AddresBookCorrespondenceMixin from "@/mixins/AddressBookCorrespondenceMixin";
import CorrespondenceForm from "@/components/form/CorrespondenceForm";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseCurrency from "@/components/form/BaseCurrency";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import { toInfoData } from "@/utils/transforms";
import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomInputs from "@/components/form/CustomInputs";

export default {
  mixins: [FormMixin, ShortcutMixin, AddresBookCorrespondenceMixin],
  data() {
    return {
      repository: "claim",
      id: this.$route.params.id,
      item: this.$route.params.item,
      correspondence_id: null,
      address_book_id: null,
      insurance_policy_data: null,
      form: {
        defaultCountryName: "Italia",
        optHomeCountries: [],
        optHomeProvinces: [],
        optHomeCouncils: [],
        quick_value: "",
        inpt_label: "",
        home_province: null,
        claim: {
          status_claim: null,
          ammount_in_reserve: null,
          claim_type: null,
          dcpd_module_filled: null,
          dcpd_module_type: null,
          deductible: null,
          insurance_policy_id: null,
          number: null,
          occurred_at: null,
          partially_settled_at: null,
          place: null,
          settled_total: null,
          risk_branch_id: null,
          is_covered: null,
          referring_at: null,
          settlement_expense: null,
        },
        correspondence: {
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
          claim: {},
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseRadio,
    BaseDatepicker,
    BaseCurrency,
    QuickSearchPolicyModal,
    CorrespondenceForm,
    CustomInputs,
  },
  methods: {
    toInfoData,
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].risk_branch_id =
        this.insurance_policy_data.insurance_risk.risk_branch_id;
      this.update(this.repository, this.id)
        .then(() => {
          let promises = [];
          if (this.correspondence_id) {
            promises.push(
              this.editSection(
                this.cor,
                this.correspondence_id,
                this.repository
              )
            );
          } else {
            promises.push(
              this.storeSection(this.cor, this.id, this.repository)
            );
          }
          Promise.all(promises)
            .then(() => {
              this.onSucess("sinsitro");
              this.shortcut("module.PFOLIO", null, "#Claims", "filterClaims");
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onSucess(resource) {
      this.$showSnackbar({
        preset: "success",
        text: `Azione completata: ${resource} creato`,
      });
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "provinceByProvinceId",
      "countries",
      "provinces",
      "councils",
    ]),
    handlePolicyModalInput(value) {
      this.form.quick_value = value.id;
      this.insurance_policy_data = value;
      this.form.inpt_label = value.number;
      this.insurance_policy_data = value;
      this.form[this.rep].risk_branch_id = value.insurance_risk.risk_branch_id;
      this.$bvModal.hide("quickSearchPolicyModal");
    },
    openQuickSearchPolicy() {
      this.form.quick_value = null;
      this.$bvModal.show("quickSearchPolicyModal");
    },
    isImported() {
      return this.insurance_policy_data.insurer.importer_id ? true : false;
    },
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id)
      .then(() => {
        const Repo = RepositoryFactory.get("insurance_policy");
        let queryString = `byAttribute[id]=${
          this.beForm[this.rep].insurance_policy_id.value
        }&byBroker`;
        Repo.index(queryString)
          .then((response) => {
            this.insurance_policy_data = response.data.data[0];
            this.form.insurance_policy_id =
              this.beForm[this.rep].insurance_policy_id.value;
            this.form.inpt_label = this.insurance_policy_data.number;
            let promises = [];
            // NOTE: pass relationString because this.id is not referring a registry but a claim here!!!
            promises.push(this.fetchCorrespondences());
            Promise.all(promises)
              .then(() => {
                this.isLoading = false;
              })
              .catch((error) => {
                this.onFailure(error);
              });
          })
          .catch((error) => {
            this.onFailure(error);
          });
      })
      .catch((error) => {
        this.onFailure(error);
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].insurance_policy_id) {
          this.insurance_policy_data = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.insurance_policy_id {
  text-align: left;
}
.info {
  display: inline-block;
  margin: 10px 20px 15px 0;
  padding: 5px 10px;
}
p {
  margin-bottom: 0.5rem;
}
</style>
